// Image Assets
import masthead from './masthead.jpg';
import cover from './cover.png';
import goldwebLogo from './goldweb_logo.png';
import digitalPrintCopy from './digital-print-copy.jpg';
import posterPrinting from './poster.jpg';
import businessCardsAndStationary from './businesscard-stationary.jpg';
import otherServices from './other.jpg';
import marketingServices from './marketing.png';
import graphicDesign from './graphicdesign.jpg';
import classifieds from './classifieds.jpg';
import publication from './publication.jpg';
import display from './display.jpg';
import calendar from './calendar.jpg';
import distribution from './distribution.jpg';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';

function App() {
  return (
    <Router>
      <div>
      <Navbar collapseOnSelect sticky="top" expand='sm' bg='dark' variant='dark'>
        <Container>
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav>
              <Nav.Link href='/'>Home</Nav.Link>                                               
              <NavDropdown title="The Mountain Merchant" id="basic-nav-dropdown">
                <NavDropdown.Item href="https://www.TheMountainMerchant.com" target="_blank">Visit The Mountain Merchant</NavDropdown.Item>
                <NavDropdown.Item href="/editions">Digital Editions</NavDropdown.Item>                
              </NavDropdown>                       
              <Nav.Link href='/contact'>Contact Us</Nav.Link>  
            </Nav>
          </Navbar.Collapse>                      
        </Container>
      </Navbar>
      </div>

      <div>        

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/products">
            <TheMountainMerchant />
          </Route>
          <Route path="/editions">
            <DigitalEditions />
          </Route>
          <Route path="/sample">
            <SampleContent />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/goldweb">
            <Goldweb />
          </Route>
          <Route path="/">
            <Goldweb />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function Home() {
  return (
    <div>  
      <Container>             
        <Row>
          <Col>
            <img src={cover} alt="cover" className="img-fluid"/>
          </Col>
        </Row>
        <Row>
          <Col>
          <h2 className="App-text-center" style={{padding:"0,10px"}}>
            The Mountain Merchant Digital Edition
          </h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <br />                  
            <div className="flipbook-embed">
              <iframe style={{width:'100%', height:'800px'}} src='https://fliphtml5.com/bookcase/whiob'  seamless='seamless' scrolling='no' frameBorder='0' allowTransparency allowFullScreen ></iframe>
            </div>
            <br />
          </Col>
        </Row>
      </Container>        
    </div>
  );
}

function DigitalEditions() {
  return (
    <div>  
      <Container>             
        <Row>
          <Col>
            <br />                  
            <div className="flipbook-embed">
              <iframe style={{width:'100%', height:'800px'}} src='https://fliphtml5.com/bookcase/whiob'  seamless='seamless' scrolling='no' frameBorder='0' allowTransparency allowFullScreen ></iframe>
            </div>
            <br />
          </Col>
        </Row>
      </Container>        
    </div>
  );
}

function Goldweb() {
  let services = [
    {title:"Digital Print & Copy", img:digitalPrintCopy, text:"We are well equipped to provide a wide variety of digital printing services for any  individual or business. Our expertise includes marketing materials, signs, corporate publications, booklet printing and more. Our professional print shop team can help with formatting, size, and all other details for personal and corporate needs." },
    {title:"Marketing Services", img:marketingServices, text:"Need Copy for Marketing Services" },
    {title:"Graphic Design Services", img:graphicDesign, text:"Need Copy for Graphic Design Servics" },
    {title:"Poster Printing", img:posterPrinting, text:"We have the team in place to provide top-level poster printing services to residents and businesses. Whether you are a local who needs a one-time presentation, or you and your team are attending one of the many conventions in the area, count on us for professional poster printing and copying services." },
    {title:"Business Cards & Stationary", img:businessCardsAndStationary, text:"Business cards are essential for any business professional or entrepreneur. We work with you to meet and exceed your expectations and offer guidance and experience to your individual needs." },
    {title:"Other Services", img:otherServices, text:"If you need digital printing or copying services that are not listed, we would love to get in touch to make your project come to life. We offer a variety of other home and office services. Contact us for more information." }
  ];
  return (
    <div>      
      <br/>
      <Container className="container-sm">
      <Row>
        <Col className="App-text-center">
          <img src={goldwebLogo} alt="cover" className="img-fluid"/>
        </Col>
      </Row>
      <Row xs={1} md={2} className="g-4">
          {Array.from(services).map((card, idx) => (
            <Col key={idx}>
              <Card key={idx}>
                <Card.Img variant="top" src={card.img} />
                <Card.Body>
                  <Card.Title>{card.title}</Card.Title>
                  <Card.Text>
                    {card.text}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}



function TheMountainMerchant() {
    let services = [
      {title:"Weekly Publication", img:publication, text:"A new weekly publication serving Amador, Calaveras, and El Dorado counties." },
      {title:"Display Advertising", img:display, text:"Allowing local businesses to advertise to their customers with display advertising." },
      {title:"Classified Advertising", img:classifieds, text:"Allowing the local community to sell, buy, and find local deals through classified advertising." },
      {title:"Community Calendar", img:calendar, text:"Providing a community events calendar and allow event organizers to get the word out about their local events." },
      {title:"Community Distribution", img:distribution, text:"Reaching the most readers with the largest circulation in the area." },      
    ];

    return (
      <div>      
        <div>
          <br/>
          <img src={masthead} alt="goldweb-logo" className="img-fluid"/>        
        </div>
        <Container className="container-sm">
        <Row xs={1} md={2} className="g-4">
            {Array.from(services).map((card, idx) => (
              <Col key={idx}>
                <Card key={idx}>
                  <Card.Img variant="top" src={card.img} />
                  <Card.Body>
                    <Card.Title>{card.title}</Card.Title>
                    <Card.Text>
                      {card.text}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    )  
}
function SampleContent() {
  let services = [
    {title:"New Masthead", img:"https://s3.amazonaws.com/media.fundable.com/profile-sections/302994/files/mm-masthead-1-my84gehwsf.jpg", },
    {title:"New Logo", img:"https://s3.amazonaws.com/media.fundable.com/profile-sections/302994/files/mm-logo-with-mountains-foboekxdxg.png",  },
    {title:"New Front Page", img:"https://s3.amazonaws.com/media.fundable.com/profile-sections/302994/files/new-cover-mm-jiviitpvmj.jpg"},
    {title:"New Sections", img:"https://s3.amazonaws.com/media.fundable.com/profile-sections/302994/files/mm-masthead-with-headings-eeuu3newwx.jpg"}
  ];
  return (
    <div className="App-text-center">      
      <Container className="container-sm">
      <br />
      <Row xs={1} md={1} className="g-4">
            {Array.from(services).map((card, idx) => (
              <Col key={idx}>
                <Card key={idx}>
                  <Card.Title><strong>{card.title}</strong></Card.Title>
                  <Card.Img variant="top" src={card.img} />                  
                </Card>
              </Col>
            ))}
          </Row>     
      </Container>
    </div>
  )
}


function Contact() {
  return (
      <div>
      <Container className="App-text-center">     
      <br/>                     
        <p><strong>Contact Us @</strong></p>
        
        <p><span className="App-contact-bullets">Kevin Barnett</span></p>

        <p><span className="App-contact-bullets">209-400-4177</span></p>

        <p><span className="App-contact-bullets">Kevin@TheMountainMerchant.com</span></p>
        <div>
          <iframe title="Contact Form" src="https://docs.google.com/forms/d/e/1FAIpQLSfvPVtVlqg8hg_Wi9jvq34SaD3b7O21cDCGEWkz6xm6oH_iBw/viewform?embedded=true"  className="App-contact" frameBorder="0" marginHeight="0" marginWidth="0" scrolling="no">Loading…</iframe>
        </div>
      </Container>
      </div>
    
  )
}



export default App;
